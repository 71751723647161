export const initialTasks = [
    {
      "task_description": "What kind of truck do you have?",
      "task_completed": false,
      "value": ""
    },
    {
      "task_description": "Where is the location that are you based?",
      "task_completed": false,
      "value": ""
    },
    {
      "task_description": "How many miles per week do you do?",
      "task_completed": false,
      "value": ""
    },
    {
      "task_description": "How often do you go home?",
      "task_completed": false,
      "value": ""
    },
    {
      "task_description": "What's your target weekly gross income?",
      "task_completed": false,
      "value": ""
    }
  ];